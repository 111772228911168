/*! purgecss start ignore */
.hs-form-iframe {
    width: 100% !important;
}
.workbench-first-sec .flex.items-center.bg-cover.w-full.overflow-hidden.relative.md\:min-h-\[500px\].md\:bg-center.bg-right.bg-gray-19 {
    background-position: bottom !important;
    min-height: 700px !important;
}
body {
    @apply font-body font-normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.careers-sec-5 div#text_slide_1 {
    background: #99b0f621;
}


.careers-sec-5 div#text_slide_2 {
    background: #B69EFF33;
}

.careers-sec-5 div#text_slide_3 {
    background: #68C3D426;
}

/*! purgecss end ignore */
.how-we-got-here {
    background: #F1F4FE;
}
.tabs-careers .p-5.border-transparent {
    display: none;
}
.tabs-careers #strategies_1 {
    border: 2px solid #7D5FFF;
    border-radius: 5px;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
margin-right:0.5em;
    font-size:19px;
    background-color: #EDECF080;
}

.tabs-careers #strategies_2 {
    border: 2px solid #3CAA81;
    border-radius: 5px;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
margin-right:0.5em;
    font-size:19px;
    background-color: #EDECF080;

}
.tabs-careers #strategies_3 {
    border: 2px solid #4578E5;
    border-radius: 5px;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
margin-right:0.5em;
    font-size:19px;
    background-color: #EDECF080;

}

.tabs-careers #strategies_4 {
    border: 2px solid #B632FF;
    border-radius: 5px;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
margin-right:0.5em;
    font-size:19px;
    background-color: #EDECF080;

}
.tabs-careers #strategies_5 {
    border: 2px solid #F2C14E;
    border-radius: 5px;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
margin-right:0.5em;
    font-size:19px;
    background-color: #EDECF080;

}

  
  
  
.tabs-careers #strategies_6 {
    border: 2px solid #30BFDA;
    border-radius: 5px;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    margin-right:0.5em;
    font-size:19px;
    background-color: #EDECF080;

}
.how-it-works-sec {
    padding-top: 2rem;
}
@media screen and (max-width: 768px) { /* Target mobile screens */
    .csi-mob {
        height: 310px !important;
    }
    .full-screen-right{
        width: 100% !important;
    }
}

.career-carousel-item {
    background: #EDEDEF;
    border-radius: 20px;
}
.career-carousel-item h5 {
    font-weight: 500;
    font-size: 20px;
}
.careers-carousel-h6{
    color: #1E1A34B2;
    font-size: 18px;
    width: 75%;
    margin: 0 auto;
    padding-top: 1em;
}
.career-carousel-item img {border-top-left-radius: 20px;border-top-right-radius: 20px;}
.careers-sec-1  h1 {
    font-size: 68px !important;
    line-height: 76px !important;
}
.careers-sec-2 h2 {
    font-size: 48px !important;
    font-weight: 400;
    color: #1E1A34;
    line-height: 50px;
}

.careers-sec-2 .p {
    width:70%;
    font-size: 20px;
    line-height: 26px;
    color: #1E1A34B2;
    font-weight:400;
    
}
.career-bottom-carousel {
    padding-top: 4rem !important;
}
.tabs-careers {
    margin-top:-4%;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.careers-sec-5 .swiper-button-next:after{
    display: none;
}
.careers-sec-5 .swiper-button-next:hover{
    color: #99B0F6 !important;
}
.careers-sec-5 .swiper-button-next, .swiper-button-prev {
    position: absolute;
    top: 90% !important;
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #99B0F6 !important;
    background: white !important;
    border-radius: 50px !important;
    padding: 5% !important;
}
.careers-sec-3{
    border-bottom: 1px solid #C5D4FD;
}
.careers-sec-4{
    border-bottom: 1px solid #C5D4FD;
}
.careers-sec-5{
    border-bottom: 1px solid #C5D4FD;
}
.careers-sec-6{
    border-bottom: 1px solid #C5D4FD;
}
.careers-sec-7{
    border-bottom: 1px solid #C5D4FD;
}

.careers-sec-6 .values-desc {
    font-size: 18px !important;
}

.careers-sec-6 .values-head {
    font-size: 18px !important;
    color: black;
    font-weight: 600;
}
.careers-sec-6 #values_1,
.careers-sec-6 #values_2,
.careers-sec-6 #values_3,
.careers-sec-6 #values_4,
.careers-sec-6 #values_5,
.careers-sec-6 #values_6 {
  position: relative; /* Ensures the pseudo-element is positioned relative to these elements */
  padding-left: 40px; /* Adjusts spacing to make room for the dot */
}

.careers-sec-6 #values_1::before {
  content: '•'; /* Unicode character for the bullet point */
  position: absolute;
  left: 0;
  top: -0.6em; /* Positioning the dot slightly above the normal text line */
  font-size: 65px; /* Font size for the dot */
  color: #7D5FFF4D; /* Color for the first value */
}

.careers-sec-6 #values_2::before {
  content: '•'; /* Unicode character for the bullet point */
  position: absolute;
  left: 0;
  top: -0.6em; /* Positioning the dot slightly above the normal text line */
  font-size: 65px; /* Font size for the dot */
  color: #C5D4FD; /* Color for the second value */
}

.careers-sec-6 #values_3::before {
  content: '•'; /* Unicode character for the bullet point */
  position: absolute;
  left: 0;
  top: -0.6em; /* Positioning the dot slightly above the normal text line */
  font-size: 65px; /* Font size for the dot */
  color: #68C3D480; /* Color for the third value */
}

.careers-sec-6 #values_4::before {
  content: '•'; /* Unicode character for the bullet point */
  position: absolute;
  left: 0;
  top: -0.6em; /* Positioning the dot slightly above the normal text line */
  font-size: 65px; /* Font size for the dot */
  color: #4A25AA80; /* Color for the fourth value */
}

.careers-sec-6 #values_5::before {
  content: '•'; /* Unicode character for the bullet point */
  position: absolute;
  left: 0;
  top: -0.6em; /* Positioning the dot slightly above the normal text line */
  font-size: 65px; /* Font size for the dot */
  color: #F2C14E80; /* Color for the fifth value */
}

.careers-sec-6 #values_6::before {
  content: '•'; /* Unicode character for the bullet point */
  position: absolute;
  left: 0;
  top: -0.6em; /* Positioning the dot slightly above the normal text line */
  font-size: 65px; /* Font size for the dot */
  color: #B632FF4D; /* Color for the sixth value */
}
.careers-sec-8 .en-circle-check {
    display: none;
}
.careers-sec-8 #list-item-0 {
    border-top: 1px solid #7D5FFFB2;
}

.careers-sec-8  #list-item-1 {
    border-bottom: 1px solid  #B632FF80;
    border-top: 1px solid #4578E5B2;
}

.careers-sec-8  #list-item-2 {
    border-bottom: 1px solid #F2C14ECC;
}


.careers-sec-8 #list-item-3 {
    border-bottom: 1px solid #68C3D4CC;
}

.careers-sec-8 #list-item-4 {
    border-bottom: 1px solid #99B0F6;
}
.how-we-got-here h2 {
    font-size: 48px;
    font-weight: 400;
    line-height: 56px;
}

.how-we-got-here .p {
    font-size: 18px;
    line-height: 26px;
    color: #1E1A34B2;
}

.tabs-careers #strategies_1:hover {
    background: #7d5fff;
    color: white;
    transition: .3s;
}

.tabs-careers #strategies_2:hover {
    background: #3caa81;
    color: white;
    transition: .3s;
}
.tabs-careers #strategies_3:hover {
    background: #4578e5;
    color: white;
    transition: .3s;
}
.tabs-careers #strategies_4:hover {
    background: #b632ff;
    color: white;
    transition: .3s;
}
.tabs-careers #strategies_5:hover {
    background: #f2c14e;
    color: white;
    transition: .3s;
}
.tabs-careers #strategies_6:hover {
    background: #30bfda;
    color: white;
    transition: .3s;
}
/* Add this to your CSS file */

/* Add this to your CSS file */

.accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 1.3s ease;
    opacity: 0;
    padding: 0;
  }
  
  .accordion-content.open {
    max-height: 1000px; /* Adjust this value based on your content's maximum height */
    opacity: 1;
    padding: 1rem 1rem; /* Adjust padding to match your layout */
    padding-left: 6.3rem;
    padding-top: 0;
    transition: 0.5s;
  }
  .mega-menu-nav ul li:before{
    display: none;
  }
  li.custom-li:before {
    display: none;
}
.careers-sec-accordion {
    background-color: #F5F6F6 !important;
    transition: background 0.10s ease, background-color 0.5s ease;
}

.careers-sec-accordion-tech {
    background: linear-gradient(115.89deg, #B632FF -6.39%, #007C91 110.85%);
    transition: background 0.10s ease, background-color 0.5s ease;
}

.careers-sec-accordion-prod-manage {
    background: linear-gradient(90deg, #4A25AA, #007C91);
    transition: background 0.10s ease, background-color 0.5s ease;
}

.careers-sec-accordion-mark {
    background: linear-gradient(90deg, #7D5FFF, #4578E5);
    transition: background 0.10s ease, background-color 0.5s ease;
}

.careers-sec-accordion-legal {
    background: linear-gradient(90deg, #99B0F6, #4578E5);
    transition: background 0.10s ease, background-color 0.5s ease;
}

.careers-sec-accordion-client-service {
    background: linear-gradient(90deg, #2CCCD3, #4578E5);
    transition: background 0.10s ease, background-color 0.5s ease;
}

.careers-sec-accordion-revenue {
    background: linear-gradient(90deg, #007C91, #3B66C1);
    transition: background 0.10s ease, background-color 0.5s ease;
}

.careers-sec-accordion-hr {
    background: linear-gradient(90deg, #F2C14E, #007C91);
    transition: background 0.10s ease, background-color 0.5s ease;
}

.careers-sec-accordion-finance {
    background: linear-gradient(90deg, #4578E5, #4A25AA);
    transition: background 0.10s ease, background-color 0.5s ease;
}
.careers-sec-accordion i.en-long-arrow {
    color: white !important;
    border: 1px solid white;
    padding: 10px;
    border-radius: 50px;
}
/* Specific styles for each type of accordion */
.careers-sec-accordion-tech h2,
.careers-sec-accordion-tech .p,
.careers-sec-accordion-tech span,
.careers-sec-accordion-tech ul li,
.careers-sec-accordion-tech a,
.careers-sec-accordion-tech svg path {
    color: white !important;
    stroke: white !important;
    transition: 0.2s ease;
}
.careers-sec-accordion-prod-manage h2,
.careers-sec-accordion-prod-manage .p,
.careers-sec-accordion-prod-manage span,
.careers-sec-accordion-prod-manage ul li,
.careers-sec-accordion-prod-manage a,
.careers-sec-accordion-prod-manage svg path {
    color: white !important;
    stroke: white !important;
    transition: 0.2s ease;
}

.careers-sec-accordion-mark h2,
.careers-sec-accordion-mark .p,
.careers-sec-accordion-mark span,
.careers-sec-accordion-mark ul li,
.careers-sec-accordion-mark a,
.careers-sec-accordion-mark svg path {
    color: white !important;
    stroke: white !important;
    transition: 0.2s ease;
}

.careers-sec-accordion-legal h2,
.careers-sec-accordion-legal .p,
.careers-sec-accordion-legal span,
.careers-sec-accordion-legal ul li,
.careers-sec-accordion-legal a,
.careers-sec-accordion-legal svg path {
    color: white !important;
    stroke: white !important;
    transition: 0.2s ease;
}

.careers-sec-accordion-client-service h2,
.careers-sec-accordion-client-service .p,
.careers-sec-accordion-client-service span,
.careers-sec-accordion-client-service ul li,
.careers-sec-accordion-client-service a,
.careers-sec-accordion-client-service svg path {
    color: white !important;
    stroke: white !important;
    transition: 0.2s ease;
}

.careers-sec-accordion-revenue h2,
.careers-sec-accordion-revenue .p,
.careers-sec-accordion-revenue span,
.careers-sec-accordion-revenue ul li,
.careers-sec-accordion-revenue a,
.careers-sec-accordion-revenue svg path {
    color: white !important;
    stroke: white !important;
    transition: 0.2s ease;
}

.careers-sec-accordion-hr h2,
.careers-sec-accordion-hr .p,
.careers-sec-accordion-hr span,
.careers-sec-accordion-hr ul li,
.careers-sec-accordion-hr a,
.careers-sec-accordion-hr svg path {
    color: white !important;
    stroke: white !important;
    transition: 0.2s ease;
}

.careers-sec-accordion-finance h2,
.careers-sec-accordion-finance .p,
.careers-sec-accordion-finance span,
.careers-sec-accordion-finance ul li,
.careers-sec-accordion-finance a,
.careers-sec-accordion-finance svg path {
    color: white !important;
    stroke: white !important;
    transition: 0.2s ease;
}
.careers-sec-accordion h2 {
    font-size: 48px;
    line-height: 56px;
    font-weight: 400;
    color: #1E1A34;
}

.careers-sec-accordion .p {
    color: #1E1A34;
    font-size: 18px;
    line-height: 26px;
}
.careers-sec-accordion div {
    font-size: 18px;
    line-height: 25px;
    color: #1E1A34;
}

.careers-sec-accordion ul li a {
    font-size: 18px;
    color: #1E1A34;
}
.careers-sec-2 h4 {
    margin-bottom: 2%;
}
.career-carousel-item {
    perspective: 1000px;
  }
  
  .card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.6s;
  }
  
  .career-carousel-item:hover .card-inner {
    transform: rotateY(180deg);
  }
  .career-carousel-item-slider-img{
    border-radius: 20px;
  }
  .career-bottom-carousel .swiper-button-next, .swiper-button-prev {
    color: white !important;
    background: none !important;
    position: absolute;
    top: 50%  !important;
}
.career-bottom-carousel .p {
    font-size: 12px;
}
  .card-front,
  .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }
  
  .card-back {
    transform: rotateY(180deg);
  }
  .text-slider-bg-col {
    background: #C5D4FD42;
}

.text-slider-desc {
    font-size: 22px;
}

.text-slider-heading {font-size: 12px;}
p.testimonial-name {
    font-size: 15px;
    font-weight: 600;
}

p.testimonial-designation {
    font-size: 15px;
}

p.testimonial-department {
    font-size: 15px;
}

img.testimonial-img {
    border-radius: 100px;
    margin-bottom: 10%;
}

.testimonial-bio {
    display: flex;
    flex-direction: column;
    margin-top: 10%;
}
.text-slider-bg-col i {
    background: white;
    border-radius: 25px;
    color: #99B0F6;
    padding: 2%;
    margin-right: 5%;
}

.career-bottom-carousel .swiper-button-next:after{
    color: white !important;
    font-size: 25px !important;
}
.career-bottom-carousel .swiper-button-prev:after{
    color: white !important;
    font-size: 25px !important;
}


// .careers-sec-accordion div#list-item-0-0 span:hover {
//     color: #B632FF;
// }

.careers-sec-accordion div .careers-sec-accordion-title
svg path {
    stroke: black;
}

.careers-sec-accordion div#list-item-0-0 .careers-sec-accordion-title:hover svg path,
.careers-sec-accordion div#list-item-0-0 .careers-sec-accordion-title:hover span {
    stroke: #B632FF ;
    color: #B632FF ;
}

.careers-sec-accordion div#list-item-0-1 .careers-sec-accordion-title:hover svg path,
.careers-sec-accordion div#list-item-0-1 .careers-sec-accordion-title:hover span {
    stroke: #4A26AB ;
    color: #4A26AB ;
}

.careers-sec-accordion div#list-item-0-2 .careers-sec-accordion-title:hover svg path,
.careers-sec-accordion div#list-item-0-2 .careers-sec-accordion-title:hover span {
    stroke: #6C57FF ;
    color: #6C57FF ;
}

.careers-sec-accordion div#list-item-0-3 .careers-sec-accordion-title:hover svg path,
.careers-sec-accordion div#list-item-0-3 .careers-sec-accordion-title:hover span {
    stroke: #0065DC ;
    color: #0065DC ;
}
.careers-sec-accordion div#list-item-1-0 .careers-sec-accordion-title:hover svg path,
.careers-sec-accordion div#list-item-1-0 .careers-sec-accordion-title:hover span {
    stroke: #0098C8 ;
    color: #0098C8 ;
}
.careers-sec-accordion div#list-item-1-1 .careers-sec-accordion-title:hover svg path,
.careers-sec-accordion div#list-item-1-1 .careers-sec-accordion-title:hover span {
    stroke: #094CCF ;
    color: #094CCF ;
}
.careers-sec-accordion div#list-item-1-2 .careers-sec-accordion-title:hover svg path,
.careers-sec-accordion div#list-item-1-2 .careers-sec-accordion-title:hover span {
    stroke: #02A364 ;
    color: #02A364 ;
}

.careers-sec-accordion div#list-item-1-3 .careers-sec-accordion-title:hover svg path,
.careers-sec-accordion div#list-item-1-3 .careers-sec-accordion-title:hover span {
    stroke: #146BED ;
    color: #146BED ;
}
.institutional-second-sec img {
    width: 600px;
}
.workbench-last-section .link {
    padding-left: 8%;
    background: #7d5fff;
    color: white;
    padding-top: 3%;
    padding-bottom: 3%;
    border-radius: 50px;
    padding-right: 8%;
}

// .careers-sec-accordion div#list-item-0-2 span:hover {
//     color: #4A26AB;
// }

// .careers-sec-accordion div#list-item-0-2 svg path {
//     stroke: black;
// }

// .careers-sec-accordion div#list-item-0-2 svg:hover path {
//     stroke: #4A26AB;
// }

// .careers-sec-accordion div#list-item-1-0 span:hover {
//     color: #6C57FF;
// }

// .careers-sec-accordion div#list-item-1-0 svg path {
//     stroke: black;
// }

// .careers-sec-accordion div#list-item-1-0 svg:hover path {
//     stroke: #6C57FF;
// }

// .careers-sec-accordion div#list-item-1-2 span:hover {
//     color: #0065DC;
// }

// .careers-sec-accordion div#list-item-1-2 svg path {
//     stroke: black;
// }

// .careers-sec-accordion div#list-item-1-2 svg:hover path {
//     stroke: #0065DC;
// }

// .careers-sec-accordion div#list-item-0-1 span:hover {
//     color: #0098C8;
// }

// .careers-sec-accordion div#list-item-0-1 svg path {
//     stroke: black;
// }

// .careers-sec-accordion div#list-item-0-1 svg:hover path {
//     stroke: #0098C8;
// }

// .careers-sec-accordion div#list-item-0-3 span:hover {
//     color: #094CCF;
// }

// .careers-sec-accordion div#list-item-0-3 svg path {
//     stroke: black;
// }

// .careers-sec-accordion div#list-item-0-3 svg:hover path {
//     stroke: #094CCF;
// }

// .careers-sec-accordion div#list-item-1-1 span:hover {
//     color: #02A364;
// }

// .careers-sec-accordion div#list-item-1-1 svg path {
//     stroke: black;
// }

// .careers-sec-accordion div#list-item-1-1 svg:hover path {
//     stroke: #02A364;
// }

// .careers-sec-accordion div#list-item-1-3 span:hover {
//     color: #146BED;
// }

// .careers-sec-accordion div#list-item-1-3 svg path {
//     stroke: black;
// }

// .careers-sec-accordion div#list-item-1-3 svg:hover path {
//     stroke: #146BED;
// }

.career-bottom-carousel .p {
    font-size: 12px;
}
.careers-sec-5 div#text_slide_4 {
    background: rgba(153, 176, 246, 0.1294117647);
}


.careers-sec-5 div#text_slide_5 {
    background: rgba(182, 158, 255, 0.2);
}
.careers-sec-5 div#text_slide_6 {
    background: rgba(104, 195, 212, 0.1490196078);
}
.careers-sec-accordion .accordion-content a {
    text-decoration: underline;
}
@media only screen and (min-device-width: 300px) and (max-device-width: 850px) {
    .tabs-careers {
        margin-top: -15%;
  }
  .careers-sec-1 h1 {
    font-size: 45px !important;
    line-height: 50px !important;
}
.social-follow-sec p {
    font-size: 15px !important;
}
.text-slider-desc{
    font-size: 18px;
}
.careers-sec-5 .swiper-button-next, .swiper-button-prev {
    top: 91% !important;
    border-radius: 100px !important;
    padding: 6% !important;
}
p.testimonial-name {
    font-size: 14px;
    font-weight: 600;
}

p.testimonial-designation {
    font-size: 13px;
}

p.testimonial-department {
    font-size: 13px;
}
.testimonial-bio {
    margin-left: 7%;
}
.careers-sec-2 .p {
    width: 100%;
}
.career-bottom-carousel .swiper-button-prev {
    position: absolute;
    top: 50% !important;
}
}
@media only screen and (max-width: 1024px) {
    .container {
        max-width: calc(100% - 24px) !important;
    }
}
.h-auto-important {
    height: auto !important;
}
.container {
    .container {
        padding: 0;
        max-width: 100% !important;
        min-width: 100% !important;
    }
}
.search-filter {
    box-shadow: none !important;
    outline: none !important;

    &:focus {
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
    }
}

.inslide-links-purple-midnight {
    a {
        @apply text-purple-midnight;
    }
}
.toggle-checkbox {
    &:checked {
        @apply right-0 transition-all;
        background-color: white !important;
        background-image: none !important;
    }
    outline: none !important;
    border: none !important;
    background-color: white !important;
    background-image: none !important;
    box-shadow: none !important;
}

.toggle-checkbox:checked+.toggle-label {
    @apply bg-purple;
}

.bullet-list {
    li {
        margin-bottom: 16px;
        padding-left: 32px;
        position: relative;
        &:before {
            content: '';
            width: 8px;
            height: 8px;
            border: 2px solid rgba(125, 95, 255, 1);
            position: absolute;
            top: 6px;
            left: 0;
            border-radius: 10px;
        }
    }
}

blockquote {
    @extend .lead;
    @apply md:my-6 mb-0 mt-6 pl-8 relative block;
    &:before {
        content: '“';
        width: 32px;
        height: 50px;
        @apply md:text-32 text-20 leading-32 absolute left-0 top-0 text-purple;
    }
}

.editor {
    &.opacity-70 {
        opacity: 1 !important;
        * {
            opacity: 0.7;
        }
        blockquote {
            opacity: 1;
            * {
                opacity: 1;
            }
        }
    }
}

.video-player {
    height: initial !important;
    width: 100% !important;
}

.legal-text-content {
    @media only screen and (min-width: 992px) {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        column-gap: 50px;
    }
    ul {
        list-style: initial;
        padding-left: 40px;
        margin: 20px 0;
    }
    ol {
        margin: 0;
        list-style: decimal;
        ol {
            list-style: lower-alpha;
            ol {
                list-style: lower-roman;
            }
        }
        padding-left: 40px;
        margin: 20px 0;
        li {
            margin-top: 8px;
            padding-left: 5px;
        }
        ol {
            list-style-type: lower-alpha;
        }
    }
}
.spacer-unique-class-L{
	height:100px !important;
}
.spacer-unique-class-M{
	height:50px !important;
}
.beyond-alpha-unique-page p.flex.items-center.mb-5.font-medium.md\:mb-8.label.text-white {
    padding-top:20%;
}
.our-episodes-h2 h2 {
    font-size: 42pt;
    color: black;
}
p.beyond-af-eyebrow{
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 2.5px;
    text-align: left;
    text-transform: uppercase;
    color: #7d5fff;
    opacity: 1 !important;
}
.lists {
    >li,
    ul li {
        position: relative;
        padding-left: 30px;
        margin-bottom: 15px;
        ul li {
            &:before {
                width: 10px;
                height: 10px;
                background-color: #7d5fff;
            }
        }
        ul,
        ol {
            padding-top: 10px;
            li {
                margin-bottom: 15px;
            }
        }
        &:before {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            border-radius: 10px;
            border: 2px solid #7d5fff;
            position: absolute;
            top: 6px;
            left: 0;
        }
    }
    ol {
        margin: 0;
        list-style: decimal;
        ol {
            list-style: lower-alpha;
            ol {
                list-style: lower-roman;
            }
        }
    }
}

// Animation (code is bottom of icon-grid comp)
// .rev-bg {
//     transform: scale(0, 1);
//     transform-origin: left center;
//     transition-delay: 0s !important;
// }

// .rev-img-wrap {
//     transform: translate(-101%, 0px);
//     transition-delay: 0s;
//     overflow: hidden;
// }
// .rev-img {
//     transform: translate(101%, 0px) scale(1.2);
//     transform-origin: right top;
//     transition-delay: 0ms;
// }
// .to-animate {
//     .rev-bg {
//         transform: scale(1, 1);
//         transition: all 800ms ease 0s !important;
//     }

//     .rev-img-wrap {
//         transition: all 800ms ease 0s;
//         transform: translate(0px, 0px);
//         transition-delay: 800ms !important;
//     }
//     .rev-img {
//         transition: all 800ms ease 0s;
//         transform: translate(0px, 0px);
//         transition-delay: 800ms;
//     }
// }

.two-col--description {
    ul {
        @apply list-disc list-outside ml-8 py-4;
    }
}

.no-scroll {
    height: 100%;
    overflow: hidden;
}

.mobile-overflow {
    background: #53565a;
    position: relative;
}
.mobile-overflow:after {
    content: '';
    position: absolute;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    background: #53565a;
    z-index: -1;
}

input::-webkit-search-cancel-button {
    display: none;
}

.freeform-content {
    @apply container py-12 lead lead--large opacity-70 mb-6;
    b {
        @apply py-2 block;
    }
    ul {
        @apply list-disc list-outside ml-8 py-4;
    }
    table {
        font-size: 0.8em;
    }
    .bwalignc {
        text-align: center;
        list-style-position: inside;
    }
    .bwalignl {
        text-align: left;
    }
    .bwalignr {
        text-align: right;
        list-style-position: inside;
    }
    .bwblockalignl {
        margin-left: 0px;
        margin-right: auto;
    }
    .bwcellpmargin {
        margin-bottom: 0px;
        margin-top: 0px;
    }
    .bwdoublebottom {
        border-bottom: double black 2.25pt;
    }
    .bwlistcircle {
        list-style-type: circle;
    }
    .bwlistdisc {
        list-style-type: disc;
    }
    .bwnowrap {
        white-space: nowrap;
    }
    .bwpadl0 {
        padding-left: 0px;
    }
    .bwpadl2 {
        padding-left: 10px;
    }
    .bwpadl4 {
        padding-left: 20px;
    }
    .bwpadr0 {
        padding-right: 0px;
    }
    .bwrowaltcolor0 {
        background-color: #c5d4fd;
    }
    .bwsinglebottom {
        border-bottom: solid black 1pt;
    }
    .bwtablemarginb {
        margin-bottom: 10px;
    }
    .bwuline {
        text-decoration: underline;
    }
    .bwvertalignb {
        vertical-align: bottom;
    }
    .bwvertalignm {
        vertical-align: middle;
    }
    .bwvertalignt {
        vertical-align: top;
    }
    .bwwidth1 {
        width: 1%;
    }
    .bwwidth100 {
        width: 100%;
    }
    .bwwidth12 {
        width: 12%;
    }
    .bwwidth71 {
        width: 71%;
    }
    .bw-subheadline-wrapper {
        @apply pb-8;
        font-size: 0.8em;
    }
}
div.long-form iframe {
    min-height: 1100px;
}
@media (min-width: 768px) {
    div.zero-padding {
        padding-bottom: 0px;
    }
}
div.zero-padding div.container div.mb-12 div.col-span-8>div {
    display: flex;
    flex-direction: column;
}
div.zero-padding div.container div.mb-12 div.col-span-8>div>div.col-span-2 {
    padding-left: 0px;
    padding-top: 1rem;
}
div.child-flex-1>div {
    flex: 1;
}
div.child-flex-1>div:nth-child(2) {
    padding-left: 2rem;
}
@media (max-width: 768px) {
    div.child-flex-1>div:nth-child(2) {
        padding-left: 0rem;
    }
}
@media only screen and (min-device-width: 300px) and (max-device-width: 850px) {
    .our-episodes-h2 h2 {
        line-height: 1;
    }
    .podcast-featured-video {
        height: 180px !important;
    }
    
    .podcastheading {
        padding-top: 10%;
    }
}
div.cta-center div.col-span-2 {
    align-self: center;
    padding-left: 0;
}
div.text-24 ul {
    font-size: 24px;
}
.podcast-arrows .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    right: auto;
    left: 40px;
    top: auto;
    bottom: -5%;
}
.podcast-arrows .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    left: 10px;
    right: auto;
    top: auto;
    bottom: -5%;
}
.swiper-button-next:after, .swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: 15px !important;
    color: #7d5fff;
}
// custom class for full width wp core image
div.full-width-image img {
    width: 100%;
    height: auto;
}

// media query for partners page hero
@media (max-width: 375px) {
    .full-hyphen span {
        font-size: 32px;
    }
}
// custom class for half width testimonial
.half-width-testimonial p.slide--subtitle {
    color: #7d5fff;
    font-size: 0.85rem;
    letter-spacing: 0.15em;
    margin-bottom: 1rem;
}
.half-width-testimonial p span,
.half-width-testimonial div.slide--tname {
    //color: white;
    opacity: 0.7;
}

.testimonials--light p span,
.testimonials--light div.slide--tname {
    color: #1e1a34;
    opacity: 0.7;
}

// custom for partners-page
.title-text>div>div {
    line-height: 32px;
    font-weight: 600;
    text-align: center;
    font-size: 20px;
}
@media (min-width: 768px) {
    .title-text>div>div {
        font-size: 24px;
    }
}
.partners-page-title h3 {
    font-size: 30px;
    line-height: 38px;
}

div.partners-padding-top {
    padding-top: 3rem;
}

.cta-padding div.text-center {
    padding: 2.875rem 0;
}
blockquote.client-quote {
    padding-left: 0;
}
blockquote.client-quote:before {
    left: -16px;
}
@media (max-width: 768px) {
    blockquote.client-quote:before {
        left: -10px;
    }
}
.client-info-text {
    color: rgba(30, 26, 52, 0.7);
}
.sidebar-border-y {
    border-top: 1px solid rgba(30, 26, 52, 0.2);
    border-bottom: 1px solid rgba(30, 26, 52, 0.2);
}
.sidebar-border {
    border-bottom: 1px solid rgba(30, 26, 52, 0.2); 
    padding: 1rem 0;
}
.sidebar-title,
.sidebar-content {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: .2px;
    text-align: left;
}
.sidebar-content {
    color: rgba(30, 26, 52, 0.6);
    padding-top: 0.5rem;
}
.alternative-bg {
    background: #F8F8F7
}